import Vue from "vue";

/*TITLE*/
document.title = "Novamarina | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Novamarina";
Vue.prototype.$subtitle = "La nueva promoción inmobiliaria que revolucionará el centro de Fuengirola apostando por un diseño rompedor en un entorno único.";

/*INTRO*/
Vue.prototype.$promoter = "Novamarina";
Vue.prototype.$introSubtitle = "La nueva promoción inmobiliaria que revolucionará el centro de Fuengirola apostando por un diseño rompedor en un entorno único.";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_bottega-caliza_602x602--20230118100158.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo1_toscana-stone_596x596-marmol-carrara-blanco_333x592--20230118100105.jpg";
Vue.prototype.$image_bath2 = "banyo2_bottega-caliza_443x443-bottega-caliza_333x592-capri-stone_45x120--20230118100111.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_bottega-caliza_602x602--20230118100158.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo1_toscana-stone_596x596-marmol-carrara-blanco_333x592--20230118100105.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo2_bottega-caliza_443x443-bottega-caliza_333x592-capri-stone_45x120--20230118100111.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20martínez%20catena%20fuengirola%20–%20málaga",
    text: "C/ Martínez Catena, Fuengirola – Málaga",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20martínez%20catena%204%20fuengirola%20–%20málaga",
    text: "C/ Martínez Catena, 4 Fuengirola – Málaga",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:683260744",
    text: "683260744",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@nova-marina.com",
    text: "info@nova-marina.com",
  },
];
